import React, { useEffect, useState } from 'react';
import OlympiadCard from './OlympiadCard';

const OlympiadSection = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch categories from WordPress API
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://istedod.tech/wp-json/custom-categories/v1/all/');
        const data = await response.json();

        if (data.success) {
          setCategories(data.data); // Set the fetched categories
        } else {
          setError('Failed to fetch categories');
        }
      } catch (err) {
        setError('Error fetching categories');
      }
    };

    fetchCategories();
  }, []);

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="px-4 sm:px-6 bg-white">
      <div className="flex flex-wrap">
        {categories.map((category) => (
          <OlympiadCard
            key={category.id}
            imageSrc={category.image_url}
            iconSrc={category.icon_url}
            title={category.title}
          />
        ))}
      </div>
    </section>
  );
};

export default OlympiadSection;
