import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../../styles/Home.module.css';
import { useTranslation } from 'react-i18next';

const Slider = () => {
  const [slides, setSlides] = useState([]);
  const router = useRouter();
  const { locale } = router;
  const { t } = useTranslation('common');

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await fetch(`https://istedod.tech/wp-json/custom-slider/v1/slides?timestamp=${new Date().getTime()}`);
        const data = await response.json();

        if (Array.isArray(data)) {
          setSlides(data);
        } else {
         }
      } catch (error) {
       }
    };

    fetchSlides();
  }, [locale]);

  const handleClick = (id) => {
    router.push(`/slider/${id}`);
  };

  const shouldLoop = slides.length > 1;

  // Function to extract text by language
  const extractTextByLanguage = (text, language) => {
    // Split text into language parts based on the language markers
    const parts = text.split(/(?=(tj|ru|en)\$)/).map(part => part.trim());

    // Loop through each part to find the one matching the selected language
    for (let part of parts) {
      if (part.startsWith(`${language}$`)) {
        return part.replace(`${language}$`, '').trim();
      }
    }

    return ''; // Return empty string if the language is not found
  };


  return (
    <div className="slider-container">
      {/* Slider Component */}
      <Swiper
        modules={[Pagination, Autoplay, Navigation]}
        spaceBetween={30}
        slidesPerView={1}
        pagination={{ clickable: true, el: '.swiper-pagination' }}
        navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        loop={shouldLoop}
        style={{ width: '100%', height: '100%' }}
      >
        {slides.length > 0 ? (
          slides.map((slide, index) => {
            const imageUrl = slide.image ? decodeURIComponent(slide.image) : '';
            const localizedTitle = extractTextByLanguage(slide.title, locale);

            if (!imageUrl) {
              console.warn(`Slide ${index} is missing an image URL.`);
              return null;
            }

            return (
              <SwiperSlide key={index}>
                <div
                  className="newsPreviewImg"
                  onClick={() => handleClick(slide.id)}
                  style={{
                    textDecoration: 'none',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                >
                  <Image
                    src={imageUrl}
                    alt={localizedTitle || 'Slide image'}
                    // fill={true}
                    height={1000}
                    width={1000}
                    // sizes="(max-width: 768px) 100vw, (max-width: 1200px)"
                    className="slider-image"
                    quality={100}
                    style={{ objectFit: 'cover' }}
                  />
                </div>
                <div className="newsTitle">
                  <div
                    onClick={() => handleClick(slide.id)}
                    style={{
                      padding: '0px',
                      textDecoration: 'none',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    {localizedTitle || t('slider.noTitle')}
                  </div>
                </div>
              </SwiperSlide>
            );
          })
        ) : (
          <div style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>
            {t('slider.noData')}
          </div>
        )}
        <div className="swiper-button-next"></div>
        <div className="swiper-button-prev"></div>
      </Swiper>
      <div className="swiper-pagination"></div>
    </div>
  );
};

export default Slider;
