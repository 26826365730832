import Image from "next/image";
import Link from "next/link";

import logo from "../../public/images/logo/logo2.png";

import Nav1 from "./Nav1";
import { useAppContext } from "@/context/Context";
import { useTranslation } from "next-i18next";



const MobileMenu = () => {
  const { mobile, setMobile } = useAppContext();
  const { t } = useTranslation('common');
  

  const copyPhoneNumber = () => {
    const phoneNumber = "(+992) 553 - 30 - 17 - 17";
    navigator.clipboard.writeText(phoneNumber)
      .then(() => {
        alert('Рақами телефон ба буфер нусхабардорӣ карда шуд!');
      })
      .catch(err => {
        console.error('Failed to copy the text: ', err);
      });
  };

  return (
    <>
      <div className={`popup-mobile-menu ${mobile ? "" : "active"}`}>

        <div className="inner-wrapper">
          <div className="inner-top">
            <div className="content">
              <div className="logo">
                <Link href="/">
                  <Image
                    src={logo}
                    alt="MJDRI Logo Images"
                  />
                </Link>
              </div>
              <div className="rbt-btn-close">
                <button
                  className="close-button rbt-round-btn"
                  onClick={() => setMobile(!mobile)}
                >
                  <i className="feather-x"></i>
                </button>
              </div>
            </div>
            <p className="description">
              {t('general.title')}
            </p>
            <ul className="navbar-top-left rbt-information-list justify-content-start">
              <li>
                <Link href="mailto:hello@example.com">
                  <i className="feather-mail"></i>mjdri@mail.ru
                </  Link>
              </li>
              <li>
                <Link href="#" onClick={copyPhoneNumber}>
                  <i className="feather-phone"></i>(+992) 553 - 30 - 17 - 17
                </Link>
              </li>
            </ul>
          </div>

          <Nav1 />

          <div className="mobile-menu-bottom">
            <div className="social-share-wrapper">
              <span className="rbt-short-title d-block">{t('tamos.title4')}</span>
              <ul className="social-icon social-default transparent-with-border justify-content-start mt--20">
                <li>
                  <Link href="https://www.facebook.com/">
                    <i className="feather-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link href="https://www.gmail.com">
                    <i className="feather-mail"></i>
                  </Link>
                </li>
                <li>
                  <Link href="https://www.instagram.com/">
                    <i className="feather-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link href="https://www.youtube.com/">
                    <i className="feather-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
