// pages/index.js
import React, { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { GridItem } from '@chakra-ui/react';

import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

// Infographic for "Минтақа" (Bubble Chart)
const BubbleChartInfographic = ({ data, title }) => {
    const maxValue = Math.max(...data.map(item => item.value));
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const containerStyle = {
        textAlign: 'center',
        padding: '30px',
        background: 'white', // Set to white to match other sections
        borderRadius: '20px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // Consistent box shadow
        marginTop: '20px',
    };

    const chartContainerStyle = {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '30px',
    };

    // Matching title style to BarChartInfographic
    const headingStyle = {
        color: '#293b8e',
        fontSize: '1.8rem',
        marginBottom: '20px',
    };

    const bubbleStyle = (size, index) => ({
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        background: `radial-gradient(circle, #293b8e, #4a69bd)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        color: 'white',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s',
        transform: hoveredIndex === index ? 'scale(1.3)' : 'scale(1)',
        boxShadow: hoveredIndex === index ? '0 4px 15px rgba(0,0,0,0.3)' : '0 2px 6px rgba(0,0,0,0.1)',
    });

    const labelStyle = {
        textAlign: 'center',
        fontSize: '1.1rem',
        fontWeight: '600',
        letterSpacing: '0.5px',
    };

    const valueStyle = {
        textAlign: 'center',
        fontSize: '1.6rem',
        fontWeight: '700',
        color: '#ff6b6b',
    };

    return (
        <div style={containerStyle}>
            <h5 style={headingStyle}>{title}</h5> {/* Title styled similarly to BarChartInfographic */}
            <div style={chartContainerStyle}>
                {data.map((region, index) => {
                    const size = (region.value / maxValue) * 100 + 40;
                    return (
                        <div
                            key={index}
                            style={bubbleStyle(size, index)}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            {hoveredIndex === index ? (
                                <div style={valueStyle}>{region.value}</div>
                            ) : (
                                <div style={labelStyle}>{region.name}</div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};


// Infographic for "Шаҳру ноҳияҳо" (Bar Chart)
const BarChartInfographic = ({ data, title }) => {
    const [animatedData, setAnimatedData] = useState([]);

    // Trigger the animation effect on mount
    useEffect(() => {
        const initialData = data.map((region) => ({ ...region, value: 0 }));
        setAnimatedData(initialData);

        setTimeout(() => {
            setAnimatedData(data); // Update to real values after initial render
        }, 300); // Delay to let initial render happen
    }, [data]);

    const containerStyle = {
        textAlign: 'center',
        padding: '20px',
        background: 'white',
        borderRadius: '20px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        marginTop: '20px',
        maxWidth: '100%',
        overflow: 'hidden',
        width: '100%',
    };

    const barChartWrapperStyle = {
        overflowX: 'auto', // Enable horizontal scrolling if necessary
        paddingBottom: '20px',
    };

    const barChartContainerStyle = {
        display: 'grid',
        gridTemplateColumns: `repeat(${data.length}, 1fr)`,
        gap: '10px',
        alignItems: 'flex-end',
        height: '300px',
        minWidth: '600px', // Minimum width for the grid, so it's scrollable on small screens
    };

    const barStyle = (value) => ({
        backgroundColor: '#293b8e',
        width: '100%',
        height: `${value * 0.4}px`,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontWeight: 'bold',
        transition: 'height 0.8s ease-in-out',
        fontSize: '0.9rem',
        textAlign: 'center',
        padding: '0 5px',
        overflow: 'hidden',
    });

    const labelStyle = {
        fontSize: '1rem',
        color: '#ff6b6b',
        fontWeight: 'bold',
        marginTop: '10px',
    };

    const titleStyle = {
        color: '#293b8e',
        fontSize: '1.8rem',
        marginBottom: '20px',
    };

    return (
        <div style={containerStyle}>
            <h5 style={titleStyle}>{title}</h5>
            <div style={barChartWrapperStyle}>
                <div style={barChartContainerStyle}>
                    {animatedData.map((region, index) => (
                        <div key={index} style={{ textAlign: 'center' }}>
                            <div style={barStyle(region.value)}>
                                <span>{region.name}</span> {/* Name displayed inside the bar */}
                            </div>
                            <div style={labelStyle}>{region.value}</div> {/* Value displayed below */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};



const PieChartInfographic = ({ data, title }) => {
    const totalValue = data.reduce((acc, curr) => acc + curr.value, 0);
    const pieSlices = data.map(item => (item.value / totalValue) * 360);
    const colors = ['#ff6b6b', '#f6b93b', '#78e08f', '#60a3bc', '#eb2f06'];

    const styles = {
        container: {
            textAlign: 'center',
            padding: '30px',
            background: 'white',
            borderRadius: '20px',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            marginTop: '20px',
        },
        chartContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '40px',
            position: 'relative',
            flexDirection: 'row', // Default: row layout
        },
        svg: {
            width: '250px',
            height: '250px',
        },
        label: {
            fontSize: '1.1rem',
            fontWeight: 'bold',
            color: '#293b8e',
            textAlign: 'left',
        },
        sliceGroup: {
            transition: 'transform 0.3s ease-in-out',
            transformOrigin: 'center', // Ensures that the zoom effect happens from the center of the slice
        },
    };

    // Media query for screens with width less than 500px
    if (window.innerWidth < 500) {
        styles.chartContainer.flexDirection = 'column'; // Stack the chart and labels vertically
    }

    // Function to calculate arc path for the pie slice
    const calculateArc = (startAngle, endAngle, radius) => {
        const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
        const x1 = 125 + radius * Math.cos((Math.PI / 180) * startAngle);
        const y1 = 125 + radius * Math.sin((Math.PI / 180) * startAngle);
        const x2 = 125 + radius * Math.cos((Math.PI / 180) * endAngle);
        const y2 = 125 + radius * Math.sin((Math.PI / 180) * endAngle);
        return `M 125 125 L ${x1} ${y1} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2} Z`;
    };

    let cumulativeAngle = 0;

    return (
        <div style={styles.container}>
            <h5 style={{ color: '#293b8e', fontSize: '1.8rem', marginBottom: '20px' }}>{title}</h5>
            <div style={styles.chartContainer}>
                <svg style={styles.svg} viewBox="0 0 250 250">
                    {data.map((item, index) => {
                        const sliceAngle = (item.value / totalValue) * 360;
                        const startAngle = cumulativeAngle;
                        const endAngle = cumulativeAngle + sliceAngle;
                        cumulativeAngle = endAngle;

                        // Calculate label position in the center of the slice
                        const labelAngle = (startAngle + endAngle) / 2;
                        const labelX = 125 + 60 * Math.cos((Math.PI / 180) * labelAngle); // Centered closer to the middle
                        const labelY = 125 + 60 * Math.sin((Math.PI / 180) * labelAngle);

                        return (
                            <g
                                key={index}
                                style={styles.sliceGroup}
                                transform-origin="125 125" // Manually set the transform origin to the center of the pie chart
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.transform = 'scale(1.1)';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.transform = 'scale(1)';
                                }}
                            >
                                <path
                                    d={calculateArc(startAngle, endAngle, 100)}
                                    fill={colors[index % colors.length]}
                                    stroke="white"
                                    strokeWidth="2"
                                />
                                <text
                                    x={labelX}
                                    y={labelY}
                                    fill="white"  // Set text color to white
                                    fontSize="12"
                                    fontWeight="bold"
                                    textAnchor="middle"
                                    alignmentBaseline="middle"
                                >
                                    {Math.round((item.value / totalValue) * 100)}%
                                </text>
                            </g>
                        );
                    })}
                </svg>
                <div>
                    {data.map((item, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                            <div style={{
                                width: '16px',
                                height: '16px',
                                backgroundColor: colors[index % colors.length],
                                marginRight: '10px',
                                borderRadius: '2px'
                            }}></div>
                            <span style={styles.label}>{item.name} — {Math.round((item.value / totalValue) * 100)}%</span>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};


const DonutChartInfographic = ({ data, title }) => {
    const totalValue = data.reduce((acc, curr) => acc + curr.value, 0);
    const slices = data.map(item => (item.value / totalValue) * 360);
    const colors = ['#4a69bd', '#f6b93b', '#60a3bc', '#ff6b6b', '#eb2f06'];

    const styles = {
        container: { textAlign: 'center', padding: '30px', background: 'white', borderRadius: '20px', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', marginTop: '20px' },
        chartContainer: { display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '40px', position: 'relative' },
        svg: { width: '250px', height: '250px' },
        label: { fontSize: '1.1rem', fontWeight: 'bold', color: '#293b8e', textAlign: 'left' },
        sliceGroup: {
            transition: 'transform 0.3s ease-in-out',
            transformOrigin: 'center', // Ensures that the zoom effect happens from the center of the slice
        },
    };

    if (window.innerWidth < 500) {
        styles.chartContainer.flexDirection = 'column'; // Stack the chart and labels vertically
    }


    // Function to calculate arc path for the pie slice (no inner radius for this)
    const calculateArc = (startAngle, endAngle, radius) => {
        const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
        const x1 = 125 + radius * Math.cos((Math.PI / 180) * startAngle);
        const y1 = 125 + radius * Math.sin((Math.PI / 180) * startAngle);
        const x2 = 125 + radius * Math.cos((Math.PI / 180) * endAngle);
        const y2 = 125 + radius * Math.sin((Math.PI / 180) * endAngle);
        return `M 125 125 L ${x1} ${y1} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2} Z`;
    };

    let cumulativeAngle = 0;

    return (
        <div style={styles.container}>
            <h5 style={{ color: '#293b8e', fontSize: '1.8rem', marginBottom: '20px' }}>{title}</h5>
            <div style={styles.chartContainer}>
                <svg style={styles.svg} viewBox="0 0 250 250">
                    {data.map((item, index) => {
                        const sliceAngle = (item.value / totalValue) * 360;
                        const startAngle = cumulativeAngle;
                        const endAngle = cumulativeAngle + sliceAngle;
                        cumulativeAngle = endAngle;

                        // Calculate label position in the center of the slice
                        const labelAngle = (startAngle + endAngle) / 2;
                        const labelX = 125 + 70 * Math.cos((Math.PI / 180) * labelAngle); // Positioned within the outer radius
                        const labelY = 125 + 70 * Math.sin((Math.PI / 180) * labelAngle);

                        return (
                            <g
                                key={index}
                                style={styles.sliceGroup}
                                transform-origin="125 125" // Manually set the transform origin to the center of the pie chart
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.transform = 'scale(1.1)';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.transform = 'scale(1)';
                                }}
                            >
                                <path
                                    d={calculateArc(startAngle, endAngle, 100)} // Pie chart with only outer radius
                                    fill={colors[index % colors.length]}
                                    stroke="white"
                                    strokeWidth="2"
                                />
                                <text
                                    x={labelX}
                                    y={labelY}
                                    fill="white"  // Set text color to white
                                    fontSize="12"
                                    fontWeight="bold"
                                    textAnchor="middle"
                                    alignmentBaseline="middle"
                                >
                                    {Math.round((item.value / totalValue) * 100)}%
                                </text>
                            </g>
                        );
                    })}
                </svg>
                <div>
                    {data.map((item, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                            <div style={{
                                width: '16px',
                                height: '16px',
                                backgroundColor: colors[index % colors.length],
                                marginRight: '10px',
                                borderRadius: '2px'
                            }}></div>
                            <span style={styles.label}>{item.name} — {Math.round((item.value / totalValue) * 100)}%</span>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};


export default function Home() {
    const { t } = useTranslation('common');
    const router = useRouter();
    const { locale } = router;

    const [activeTab, setActiveTab] = useState(t('infographic.region'));
    const [infographicData, setInfographicData] = useState({
        [t('infographic.region')]: { title: '', data: [] },
        [t('infographic.city')]: { title: '', data: [] },
        [t('infographic.new_schools')]: { title: '', data: [] },
        [t('infographic.middle_schools')]: { title: '', data: [] }
    });

    const parseMultilingualText = (text) => {
        const regex = new RegExp(`${locale}\\$\\s([^$]+)(?=\\s(?:tj|ru|en)\\$|$)`);
        const match = text.match(regex);
        return match ? match[1].trim() : text; // Fallback to the original text if no match found
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const regionResponse = await fetch('https://istedod.tech/wp-json/custom-api/v1/regions');
                const districtsResponse = await fetch('https://istedod.tech/wp-json/custom-api/v1/districts');
                const newTypeSchoolsResponse = await fetch('https://istedod.tech/wp-json/custom-api/v1/new-type-schools');
                const secondaryEducationResponse = await fetch('https://istedod.tech/wp-json/custom-api/v1/secondary-education-schools');

                const regionsData = await regionResponse.json();
                const districtsData = await districtsResponse.json();
                const newTypeSchoolsData = await newTypeSchoolsResponse.json();
                const secondaryEducationData = await secondaryEducationResponse.json();

                const sortedNewTypeSchoolsData = newTypeSchoolsData
                    .map(school => ({
                        name: parseMultilingualText(school.school_name),
                        value: parseInt(school.school_number, 10)
                    }))
                    .sort((a, b) => b.value - a.value);

                const sortedSecondarySchoolsData = secondaryEducationData
                    .map(school => ({
                        name: parseMultilingualText(school.school_name),
                        value: parseInt(school.school_number, 10)
                    }))
                    .sort((a, b) => b.value - a.value);

                setInfographicData({
                    [t('infographic.region')]: {
                        title: t('infographic.title1'),
                        data: regionsData.map(region => ({
                            name: parseMultilingualText(region.region_name),
                            value: region.region_number
                        }))
                    },
                    [t('infographic.city')]: {
                        title: t('infographic.title2'),
                        data: districtsData.map(district => ({
                            name: parseMultilingualText(district.district_name),
                            value: district.district_number
                        }))
                    },
                    [t('infographic.new_schools')]: {
                        title: t('infographic.title3'),
                        data: sortedNewTypeSchoolsData
                    },
                    [t('infographic.middle_schools')]: {
                        title: t('infographic.title4'),
                        data: sortedSecondarySchoolsData
                    }
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [locale, t]); // Trigger when locale or translations change

    const navbarItems = Object.keys(infographicData);

    return (
        <div style={{ padding: '10px', backgroundColor: '#fff', width: '100%' }}>
            <nav style={{
                display: 'flex',
                justifyContent: window.innerWidth > 1000 ? 'center' : 'flex-start',
                gap: '15px',
                padding: '10px',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                width: '100%',
                boxSizing: 'border-box',
            }}>
                {navbarItems.map(item => (
                    <button
                        key={item}
                        onClick={() => setActiveTab(item)}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: activeTab === item ? '#293b8e' : '#ddd',
                            color: activeTab === item ? 'white' : '#333',
                            borderRadius: '30px',
                            border: 'none',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            flexShrink: 0,
                            minWidth: '150px',
                            textAlign: 'center',
                        }}
                    >
                        {item}
                    </button>
                ))}
            </nav>
            {activeTab === t('infographic.region') && infographicData[activeTab] && (
                <BubbleChartInfographic
                    data={infographicData[activeTab].data}
                    title={infographicData[activeTab].title}
                />
            )}
            {activeTab === t('infographic.city') && infographicData[activeTab] && (
                <BarChartInfographic
                    data={infographicData[activeTab].data}
                    title={infographicData[activeTab].title}
                />
            )}
            {activeTab === t('infographic.new_schools') && infographicData[activeTab] && (
                <PieChartInfographic
                    data={infographicData[activeTab].data}
                    title={infographicData[activeTab].title}
                />
            )}
            {activeTab === t('infographic.middle_schools') && infographicData[activeTab] && (
                <DonutChartInfographic
                    data={infographicData[activeTab].data}
                    title={infographicData[activeTab].title}
                />
            )}
        </div>
    );
}