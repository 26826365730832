import React, { useEffect, useState } from 'react';
import NewsCard from './NewsCard';
import { useRouter } from 'next/router';

const NewsSection = () => {
  const [newsData, setNewsData] = useState([]);
  const router = useRouter();
  const { locale } = router;

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch('https://istedod.tech/wp-json/custom-news/v1/latest');
        const data = await response.json();
        setNewsData(data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, [locale]);

  // Function to extract text by language
  const extractTextByLanguage = (text, language) => {
    // Split text into language parts based on the language markers
    const parts = text.split(/(?=(tj|ru|en)\$)/).map(part => part.trim());

    // Loop through each part to find the one matching the selected language
    for (let part of parts) {
      if (part.startsWith(`${language}$`)) {
        return part.replace(`${language}$`, '').trim();
      }
    }

    return ''; // Return empty string if the language is not found
  };


  return (
    <section className="mx-auto bg-white">
      <h4 className="text-center py-3 m-0 tracking-tight leading-loose text-blue-900">Хабарҳо</h4>
      <div className="flex flex-wrap -mx-2.5">
        {newsData.map((news, index) => (
          <div key={index} className="w-full sm:w-1/2 lg:w-1/3 px-2.5 pb-5">
            <NewsCard
              id={news.id}  // Pass the news ID here
              imageSrc={news.image_url}
              date={new Date(news.created_at).toLocaleDateString()}
              title={extractTextByLanguage(news.title, locale)} // Extract language-specific title
            />
          </div>
        ))}
      </div>
    </section>
  );
}

export default NewsSection;
